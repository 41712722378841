<template>
  <a-table rowKey="id" :data-source="list" :pagination="pagination" :loading="listLoading" @change="handleTableChange">
    <a-table-column key="roleName" title="角色" align="center" data-index="roleName" width="120px" />
    <a-table-column key="roleDescribe" title="角色描述" align="center" data-index="roleDescribe" width="120px">
      <template slot-scope="roleDescribe">
        <span>
          {{ roleDescribe ? roleDescribe : '-' }}
        </span>
      </template>
    </a-table-column>
    <a-table-column key="createTimestamp" title="添加时间" align="center" data-index="createTimestamp" width="120px" />
    <!--    <a-table-column key="roleStatus" title="启用" align="center" data-index="roleStatus" width="80px">-->
    <!--      <template slot-scope="roleStatus">-->
    <!--        <a-badge-->
    <!--          :status="{ AVAILABLE: 'success', UNAVAILABLE: 'error' }[roleStatus]"-->
    <!--          :text="roleStatus | StatusText()"-->
    <!--        ></a-badge>-->
    <!--      </template>-->
    <!--    </a-table-column>-->
    <a-table-column key="actions" title="操作" align="center" width="160px">
      <template slot-scope="record">
        <a-space>
          <a-button v-if="$auth('role_update')" type="link" size="small" @click="handleEdit(record)"
            ><a-icon type="edit" />编辑</a-button
          >
          <a-button
            v-if="$auth('role_delete')"
            type="link"
            size="small"
            @click="handleRemove(record)"
            class="error-color"
            ><a-icon type="delete" />删除</a-button
          >
        </a-space>
      </template>
    </a-table-column>
  </a-table>
</template>

<script>
import * as roleApi from '@/api/role'
export default {
  data() {
    return {
      listLoading: false,
      list: [],
      query: {},
      pagination: {},
    }
  },
  mounted() {},
  methods: {
    initData(query = {}, pagination) {
      const { initPagination, getData } = this

      this.query = query
      this.pagination = initPagination()
      // 编辑返回要记住页码
      if (pagination.hasOwnProperty('current') && pagination.hasOwnProperty('pageSize')) {
        const { current, pageSize } = pagination
        this.pagination.current = Number(current)
        this.pagination.pageSize = Number(pageSize)
      }
      return getData()
    },
    getData() {
      this.listLoading = true
      const {
        pagination: { current: pageIndex, pageSize },
        query,
      } = this
      return roleApi
        .list({ ...query, pageIndex, pageSize })
        .then((res) => {
          const { list, totalItems: total } = res.data
          this.list = list
          this.pagination.total = total
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    initPagination() {
      return {
        current: 1,
        pageSize: 10,
        showTotal: (total) => {
          const element = this.$createElement
          const {
            pagination: { pageSize },
          } = this
          return element('div', {}, [
            element('span', '共 '),
            element('span', { class: 'primary-color' }, pageSize ? Math.ceil(total / pageSize) : Math.ceil(total / 10)),
            element('span', ' 页'),
            element('span', ' / '),
            element('span', { class: 'primary-color' }, total),
            element('span', ' 条数据'),
          ])
        },
        showSizeChanger: true,
        total: 0,
        pageSizeOptions: ['10', '20', '50', '100'],
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const { getData } = this
      this.pagination.current = pagination.current
      this.pagination.pageSize = pagination.pageSize

      getData()
    },
    handleRemove(record) {
      const confirm = this.$confirm({
        title: '删除',
        content: '您确定要删除吗',
        onOk: () => {
          return roleApi
            .remove(record.id)
            .then(() => {
              this.$notification['success']({ message: '操作成功' })
              this.getData()
            })
            .catch((err) => {
              confirm.destroy()
              this.$info({
                title: '不可删除',
                content: err.msg,
              })
            })
        },
      })
    },
    handleEdit({ id }) {
      const {
        pagination: { current, pageSize },
      } = this
      const query = { current, pageSize }

      this.$router.push({
        path: '/permission/role/edit/' + id,
        query,
      })
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .ant-badge-status-text,
/deep/ .ant-btn {
  font-size: 12px;
}
</style>
