<template>
  <div>
    <a-card :bordered="false" style="margin-bottom: 24px">
      <a-row :gutter="[24, 0]">
        <a-form-model class="ant-advanced-search-form">
          <a-col :lg="9" :md="12" :sm="24">
            <a-form-model-item label="角色名称：">
              <a-input-search
                v-model="query.roleName"
                placeholder="请输入角色名称"
                style="width: 100%"
                @search="initData"
              />
            </a-form-model-item>
          </a-col>
        </a-form-model>
      </a-row>
    </a-card>
    <a-card :body-style="{ padding: 0 }">
      <div slot="title">
        <span>角色列表</span>
      </div>
      <div slot="extra">
        <a-button v-if="$auth('role_add')" type="primary" shape="round" @click="$router.push('/permission/role/create')"
          ><a-icon type="plus" />新增角色</a-button
        >
      </div>
      <main-table ref="table"></main-table>
    </a-card>
  </div>
</template>

<script>
import MainTable from './components/Table'
export default {
  name: 'PermissionRoleList',
  components: {
    MainTable,
  },
  data() {
    return {
      query: { roleName: '' },
    }
  },
  activated() {
    if (this.ready) {
      this.initData()
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.ready = true
    })
    this.initData()
  },
  methods: {
    initData() {
      // 编辑返回要记住页码
      const { query: pagination } = this.$route
      this.$refs.table.initData(this.query, pagination)
    },
  },
}
</script>

<style lang="less" scoped>
.ant-advanced-search-form /deep/ .ant-form-item {
  display: flex;
  margin-bottom: 0;
}
.ant-advanced-search-form /deep/ .ant-form-item-control-wrapper {
  flex: 1;
}
</style>
